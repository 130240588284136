$text-color: #fff;
$button-color: #8c8e95;
$secondary-color: #e3c050;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,900;1,900&display=swap');


*,
*:after,
*:before{
  box-sizing: border-box;
}

body {
  background-color: #1f1f1f !important;
  // background-color: #131313 !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: #C0C0C0;
  font-size: 16px;
  line-height: (24/16);
  margin: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 900;
  font-family: "Playfair Display", "Times New Roman", Times, serif;
  color: #fff;
}

button, input, optgroup, select, textarea{
  font-family: inherit;
}

.container{
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px){
    max-width: 540px;
  }
  @media (min-width: 768px){
    max-width: 720px;
  }
  @media (min-width: 992px){
    max-width: 960px;
  }
  @media (min-width: 1200px){
    max-width: 1140px;
  }
  @media (min-width: 1400px){
    max-width: 1320px;
  }

  &:after{
    content: '';
    clear: both;
    display: block;
  }
}

.list-unstyled{
  list-style: none;
  margin: 0;
  padding: 0;

  > li{
    list-style-type: none;
  }
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center;
}

.align-item-center {
  align-items: center;
}

.w-100 {
  width: 100% !important;
}

.d-none {
  display: none !important;
}

.d-show {
  display: block !important;
}

.ads_data-show {
  display: none;
}

.btn-gold {
  padding: 10px 0px;
  background: #000000;
  border: 0.60625px solid $secondary-color;
  border-radius: 6px;
  font-size: 16px;
}

.btn-grey {
  padding: 10px 0px;
  border: 1px solid #454648;
  border-radius: 6px;
  background: transparent;
  font-size: 16px;
}

.pe-none {
  pointer-events: none;
}

.color-primary {
  color: #E3C050;
}

.sixmeetings {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  font-family: "Roboto";
  font-size: 16px;

  @media (max-width: 767px) {
    overflow-y: auto;
    overflow-x: hidden;
    line-height: 24px;
  }

  &-content {
    display: flex;
    height: calc(100% - 65px);
  }

  &-users {
    width: 320px;
    background: #131313;
    padding: 10px;
    display: flex;
    flex-direction: column;
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
    transition: 0.4s;
    transform: translateX(-400px);
    top: 62px;

    @media (min-width: 768px){
      top: 67px;
    }

    > div{
      width: 100%;
      height: 100%;
      border: 1px solid #373737;
      background-color: #1f1f1f;
      box-shadow: 0 4px 50px #131313;
      border-radius: 11px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .sixmeetings_btn-wrapper{
      padding: 16px;
      border-bottom: 1px solid #2b2b2b;
    }

    &.open {
      transform: translateX(0);
    }

    .side-nav-list{
      padding: 10px;
    }
  
    .sbwListButton{
      padding: 12px;
      font-size: 15px;
      line-height: 135%;
      display: flex;
      align-items: center;
      border-radius: 8px;
      color: rgb(192, 192, 192);
  
      &:hover{
        color: #e3c050;
        background-color: transparent;
      }
  
      > div{
        margin-top: 0;
        margin-bottom: 0;
      }
  
      .MuiListItemText-primary{
        font: inherit;
      }
  
      .navicon{
        position: absolute;
        left: 15px;
        top: calc(50% + 2px);
        transform: translateY(-50%);
      }
    }

    &-newads {

      .adsgpt-logo {
        width: 36.55px;
        height: 23.55px;
      }

      .adsgpt-logo img {
        width: 100%;
      }

      &-btn {
        display: block;
        width: 100%;
        border-radius: 6px;
        background-color: #000;
        border: 1px solid #E3C050;
        color: #fff;
        padding: 13px;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &.add {
          width: inherit;

          @media (max-width: 768px) {
            display: none;
          }
          
          @media (max-width: 992px) {
            .btn-text {
              display: none;
            }
          }
        }

        &:hover{
          color: #fff;
          text-decoration: none;
          background-color: #3f3f3f;
        }

        svg{
          width: 20px;
          height: 20px;
        }
      }
    }

    &-title {
      height: calc(100% - 50px);
      overflow-y: auto;

      .title-input {
        background: transparent;
        border: 1px solid $secondary-color;
        border-radius: 3px;
        height: 24px;
        font-weight: 700;
        margin-left: -3px;
        outline: none;
        color: #fff;
        width: 180px;

        &:focus {
          border: 1px solid $secondary-color;
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f100;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #414141;
        border-radius: 10px;
      }

      &-my_conversation,
      &-shared_conversation {
        padding: 24px 12px;
        border-top: 1px solid #1f1f1f;
        padding-bottom: 70px;

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f100;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #414141;
          border-radius: 10px;
        }

        p {
          font-weight: 700;
          font-size: 11px;
          text-transform: uppercase;
          color: #8c8e95;
        }

        &-users_ads {
          display: flex;
          align-items: center;
          padding: 12px 0px;
          gap: 8px;
          border-bottom: 1px solid #373737;
          cursor: pointer;

          &-inner {
            gap: 8px;

            p {
              font-size: 16px;
              font-weight: 400;
              width: 155px;
              height: 24px;
              overflow: hidden;
              text-transform: none;
              text-overflow: ellipsis;
              white-space: nowrap;

              @media (max-width: 768px) {
                width: 120px;
              }
            }

            img {
              width: 16px;
              height: 16px;
            }

            &-title {
              color: $button-color !important;
              text-transform: none !important;
              font-weight: 400 !important;

              &.active {
                // text-transform: uppercase !important;
                font-weight: 700 !important;
                color: $text-color !important;
                margin: 0;
              }
            }
          }

          &-updateicons {
            display: flex;
            align-items: center;
            gap: 6px;
            width: 100%;
            justify-content: flex-end;

            img {
              width: 15.55px;
              height: 15.55px;
              cursor: pointer;
              filter: brightness(1);

              // opacity: 0.8;
              &:hover {
                filter: brightness(2);
              }
            }
          }
        }

        &-clearconversation {
          background-color: transparent;
          border: none;
          display: flex;
          padding: 12px 0px;
          gap: 8px;
          align-items: center;
          border-bottom: 1px solid #373737;
          width: 100%;
          color: $button-color;

          &:hover {
            color: #d6d8e0;
          }
        }

        @media (max-width: 1440px) {
          padding: 24px 10px;
        }

        @media (max-width: 768px) {
          padding: 20px 8px;
        }
      }
    }

    &-features {
      position: absolute;
      right: 10px;
      top: -3px;
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: center;

      .dropdown {
        position: relative;
        width: 35px;
        height: 35px;

        .dropdown-toggle {
          border: none;
          padding: 0;
          background-color: none;

          &:hover {
            background: none;
          }

          &:after {
            display: none;
          }
        }

        .user-avatar {
          top: 5px;
        }

        .dropdown-menu {
          background: #1f1f1f;
          color: white;

          a {
            color: white;
          }
        }
      }

      .login-btn {
        background: #1f1f1f;
        border: 1px solid #373737;
        border-radius: 6px;
        text-align: left;
        border: 1px solid #e3c15000;
        color: $text-color;

        &:hover {
          border: 1px solid $secondary-color;
        }
      }

      button {
        background: none;
        padding: 12px 10px;
        gap: 8px;
        color: $button-color;
      }

      @media (max-width: 1024px) {
        // height: 25%;
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-end;
      }

      @media (max-width: 768px) {
        // min-height: 27%;
        // max-height: 100%;
      }
    }
  }

  &-logo {
    width: 36.55px;
    height: 23.55px;
    object-fit: cover;

    img {
      width: 100%;
    }
  }

  &-adss {
    width: 100%;
    // background: #000;
    position: relative;

    &-inner {
      background-color: #1f1f1f;
    }

    &-modals {
      border-bottom: 1px solid #373737;
      padding: 10px;
      color: $text-color;

      &.header {
        height: 65px;
        background-color: #1f1f1f;
        position: relative;
        z-index: 9;

        .sixmeetings-users-features{
          top: 9px;
        }
      }

      .header-btn {
        color: #8c8e95;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border: 1px solid transparent;
        border-radius: 6px;
        margin-top: 1px;
        transition: color 0.3s ease, border-color 0.3s ease;
        background-color: transparent;
        top: 4px;
        position: absolute;
        right: 60px;

        @media (max-width: 767px) {
          width: 34px;
          height: 34px;
        }

        &:hover,
        &.show {
          text-decoration: none;
          color: #fff;
          border-color: #575757;
          background-color: #373737;
          color: #fff;
        }

        &.show {
          border-color: #e3c050;
          background-color: #131313;
        }
      }

      .apps-opener {
        button {
          color: inherit;
          background-color: transparent !important;
        }
      }

      .app-content-dropdown {
        text-align: center;
        top: 100%;
        right: -50px;
        position: absolute;
        width: 235px;
        padding-top: 12px;
      }

      .app-content-head {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;
      }

      .dropdown-menu {
        padding: 20px;
        position: relative;
        border: 1px solid #1f1f1f !important;
        box-shadow: 0 4px 50px #131313 !important;
        border-radius: 8px !important;
        background-color: #131313 !important;

        &:after,
        &:before {
          bottom: 100%;
          right: 52px;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(19, 19, 19, 0);
          border-bottom-color: #131313;
          border-width: 10px;
          margin-left: -10px;
        }

        &:before {
          border-color: rgba(31, 31, 31, 0);
          border-bottom-color: #1f1f1f;
          border-width: 10px;
          margin-left: -10px;
        }

        .dropdown-item {
          text-align: left;

          &:first-child {
            border-radius: 8px 8px 0 0;
          }

          &:last-child {
            border-radius: 0 0 8px 8px;
          }

          +button {
            width: 100%;
            display: block;
            text-align: left;
          }
        }
      }

      .profile-dropdown .dropdown-menu {
        padding: 0;
      }

      .profile-dropdown .dropdown-menu {
        margin-top: 11px;

        &:before,
        &:after {
          right: 5px;
        }
      }

      .features-dropdown {
        display: none !important;
      }

      .profile-dropdown .dropdown-menu .dropdown-item,
      .profile-dropdown .dropdown-menu .dropdown-item+button {
        padding: 11px 16px;
        color: #c0c0c0;
        font-weight: 400;
        font-size: 16px;

        &:hover,
        &:focus {
          background-color: #373737;
          text-decoration: none;
        }

        +.dropdown-item,
        +button {
          border-top: 1px solid #1f1f1f;
        }
      }

      .profile-dropdown .dropdown-menu button:last-child {
        border-radius: 0 0 8px 8px;
      }

      .app-content-list>li>a {
        color: #fff;
        transition: color 0.3s ease;
      }

      .app-content-list>li>a:hover {
        text-decoration: none;
        color: #e3c050;
      }

      .app-content-list-title {
        font-weight: 400;
        display: block;
      }

      .app-content-list i {
        color: #c0c0c0;
        display: block;
        margin-bottom: 5px;
      }

      .app-content-title {
        display: block;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 14px;
      }

      .app-content-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin: 0 -5px;
        padding: 0;
        row-gap: 10px;

        >li {
          width: 33.333%;
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      .sidebar-opener {
        position: absolute;
        left: 20px;
        top: 14px;
        z-index: 9;

        >span,
        &:after,
        &:before {
          position: absolute;
          left: 7px;
          right: 7px;
          background-color: currentColor;
          border-radius: 99px;
          height: 3px;
          transition: all 0.3s ease;

          @media (max-width: 767px) {
            height: 2px;
          }
        }

        &:after,
        &:before {
          content: "";
        }

        &:before {
          top: 7px;
        }

        &:after {
          bottom: 7px;
        }

        &--close {
          border-color: #e3c050;
          color: #fff;

          &:before {
            transform: rotate(45deg) translate(7px, 7px);

            @media (max-width: 767px) {
              transform: rotate(45deg) translate(6px, 6px);
            }
          }

          &:after {
            transform: rotate(-45deg) translate(7px, -6px);

            @media (max-width: 767px) {
              transform: rotate(-45deg) translate(6px, -5px);
            }
          }

          >span {
            opacity: 0;
            visibility: hidden;
          }
        }

        >span {
          top: 16px;

          @media (max-width: 767px) {
            top: 15px;
          }
        }
      }

      .user-avatar {
        width: 35px;
        height: 35px;
        // position: absolute;
        // right: 10px;
        // top: 17px;
        z-index: 9;
        background: #2b2b2b;
        border-radius: 100%;
        color: #c0c0c0;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          border-radius: inherit;
        }
      }

      .sixmeetings-header-logo {
        position: absolute;
        left: 70px;
        top: 15px;
        z-index: 2;

        @media (max-width: 767px) {
          max-width: 70px;
          left: 60px;
        }

        img {
          max-width: 100%;
          vertical-align: top;
          height: 38px;
        }
      }
    }

    &-inner {
      flex-direction: column;
      align-items: center;
      gap: 55px;
      width: 88%;
      margin: 0 auto;
      height: 100%;
      padding: 24px;

      @media (max-width: 767px) {
        height: auto;
      }

      &.no-ads {
        width: 50%;
        height: 100%;
        justify-content: center;
        gap: 40px;

        @media (max-width: 767px) {
          width: 100%;
          gap: 20px;
        }

        .sixmeetings-adss-inner-logo {
          opacity: 1;
          margin-top: 0;
          position: static;
          transform: none;

          @media (max-width: 767px) {
            max-width: 50px;
          }
        }

        .sixmeetings-searchbox {
          width: 50%;
          max-width: none;
          top: 50%;
          bottom: auto;
          margin-top: 0;
          position: static;
          width: 100%;
          max-width: 508px;
        }
      }

      .response-area-outer {
        position: relative;
        width: 100%;

        &:after {
          content: "";
          height: 120px;
          width: 100%;
          position: absolute;
          bottom: 0;
          background: linear-gradient(180deg,
              rgb(31 31 31 / 8%) 0%,
              rgb(31 31 31) 50%);

          @media (max-width: 1024px) {
            bottom: -30px;
          }

          @media (max-width: 767px) {
            content: none;
          }
        }
      }

      &.response-area {
        height: 85vh;
        overflow-y: scroll;
        padding: 0 31px 95px;
        background-color: #1f1f1f;

        @media (max-width: 576px) {
          height: 100vh;

          width: 100%;
          padding: 0 0 170px 0;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f100;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #41414101;
          border-radius: 10px;
        }
      }

      &-logo {
        width: 94.1px;
        height: 60.65px;
        object-fit: cover;

        @media (max-width: 767px) {
          width: 54px;
          height: 40px;
        }

        &.background-logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, calc(-50% - 5px));
          opacity: 0.1;
        }

        img {
          width: 100%;
        }

        @media (max-width: 1300px) {}
      }

      &-guide {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @media (max-width: 767px) {
          padding-bottom: 90px;
        }

        &-item {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          gap: 17px;

          img {
            width: 24px;
            height: 24px;
          }

          span {
            font-weight: 700;
            font-size: 18px;
            text-align: center;
            color: #e9e9e9;
            width: 60%;

            @media (max-width: 1024px) {
              font-size: 14px;
            }

            @media (max-width: 768px) {
              width: 80%;
            }
          }

          button {
            background: #2b2b2b;
            border-radius: 6px;
            padding: 10px;
            color: #c0c0c0;
            width: 250px;
            border: 1px solid #e3c15000;

            &:hover {
              background: #1f1f1f;
              border: 1px solid $secondary-color;
              border-radius: 6px;
            }

            @media (max-width: 1024px) {
              width: 220px;
              font-size: 14px;
            }

            @media (max-width: 768px) {
              width: 140px;
              font-size: 13px;
            }

            @media (max-width: 576px) {
              width: 100%;
              font-size: 13px;
            }
          }
        }

        @media (max-width: 1024px) {
          gap: 8px;
        }

        @media (max-width: 576px) {
          display: flex;
          flex-direction: column;
        }
      }

      &-response {
        width: 100%;
        min-width: 100%;
        background: #131313;
        border-radius: 6px;
        padding: 9px 12px;
        gap: 12px;
        color: white;
        color: #e9e9e9;
        margin-bottom: 12px;

        @media (max-width: 767px) {
          padding: 12px;
          gap: 12px;
        }

        &.sixmeetings-response {
          background: transparent !important;
          position: relative;
        }

        .sixmeetings-adss-inner-response-slider {
          color: #c0c0c0;
        }

        &-logo {
          width: 30px;
          height: 30px;
          min-width: 30px;
          background: #1f1f1f;
          border-radius: 50%;

          .response-logo {
            border-radius: 0% !important;
          }

          // border: 1px solid red;
          img {
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .loader {
          width: 100%;
          display: flex;
          justify-content: center;

          img {
            width: 8%;
          }
        }
      }

      @media (max-width: 1300px) {
        gap: 25px;
      }

      @media (max-width: 1024px) {
        width: 90%;
        gap: 280px;
      }

      @media (max-width: 768px) {
        padding: 20px;
        gap: 100px;
      }

      @media (max-width: 576px) {
        padding: 20px;
        gap: 30px;
        width: 100%;
      }
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &-searchbox {
    position: absolute;
    width: 70%;
    bottom: 24px;

    @media (max-width: 767px) {
      position: fixed;
      bottom: 0;
      padding-bottom: 40px;
      background-color: #1f1f1f;
    }

    &-regenrate {
      gap: 10px;
      margin-bottom: 16px;

      button {
        background: #1f1f1f;
        border: 1px solid #454648;
        border-radius: 6px;
        // padding: 12px 24px;
        padding: 10px 18px;
        color: $text-color;
        font-size: 16px;
        gap: 8px;
        color: #c0c0c0;

        &:hover {
          background-color: #373737;
          border-color: #575757;
        }

        img {
          width: 16px;
          height: 16px;
        }

        @media (max-width: 1440px) {
          padding: 10px 18px;
        }

        @media (max-width: 767px) {
          font-size: 12px;
          padding: 8px 14px;
        }
      }
    }

    &-inner {
      align-items: flex-end;
      display: flex;
      align-items: center;
      padding: 12px 16px;
      gap: 24px;
      background: #131313;
      border-radius: 6px;
      width: 100%;
      height: 48px;
      position: relative;
      border: 1px solid #373737;
      position: relative;

      &:focus {
        border-color: #e3c050;
      }

      &::placeholder {
        color: #575757;
        opacity: 1;
      }

      .creating-msg {
        position: absolute;
        background: #2b2b2b;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        color: #c0c0c0;

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          margin: 0;
        }

        button {
          background: #000;
          border: 1px solid #e3c050;
          border-radius: 6px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 15px;
          color: white;
          font-size: 16px;
          line-height: 16px;
          min-width: 84px;
          margin: 0 12px;

          &:hover {
            background: #2b2b2b;
          }
        }
      }

      img {
        right: 30px;
        bottom: 15px;
        width: 20px;
        position: absolute;
        cursor: pointer;
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }
    }

    &-input {
      padding-right: 35px;
      width: 100%;
      background: transparent;
      outline: none;
      border: none;
      color: $text-color;
      resize: none;

      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f100;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #414141;
        border-radius: 10px;
      }
    }

    @media (max-width: 1024px) {
      width: 90%;
    }
  }

  &-sharedbtn_popup {
    color: $text-color;
    justify-content: space-between;
    gap: 24px;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: red;
    width: 393px;
    min-height: 282px;
    background: #2b2b2b;
    border: 1px solid #454648;
    border-radius: 6px;
    padding: 24px;

    @media (max-width: 767px) {
      width: 275px;
      position: fixed;
      padding: 18px;
    }

    &.delete_popup {
      height: 180px;

      h4 {
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
    }

    .popup-cross_icon {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 15px;

      @media (max-width: 767px) {
        top: 6px;
        right: 8px;
      }
    }

    &-email {
      flex-direction: column;
      width: 100%;
      gap: 8px;

      .email-input {
        display: flex;
        gap: 10px;
        min-height: 86px;
        background: #131313;
        border: 1px solid #373737;
        border-radius: 6px;

        .react-tagsinput {
          width: 100%;
          background-color: transparent;
          border: none;
          padding: 10px !important;

          &-tag {
            background: #373737;
            border: none;
            margin: 0;
            padding: 6px 8px;
            margin: 0 5px 5px;

            a {
              &:before {
                color: #e3c050;
              }
            }
          }

          &-input {
            width: 200px;
            margin: 0;
            background-color: transparent;
          }
        }

        .react-tagsinput &-tag {
          padding: 6px 8px;
          background: #373737;
          height: 28px;
          border-radius: 6px;
          color: #fff;
        }

        textarea {
          outline: none;
          border: none;
          background: #1f1f1f;
          resize: none;
          width: 100%;
          height: 100%;

          &:focus {
            outline: none;
          }
        }
      }

      span {
        font-size: 12px;
        color: #8c8e95;
        float: left;
      }
    }

    &-btn {
      gap: 24px;

      .btn-cancel,
      .btn-submit {
        width: 150px;
        height: 48px;
        color: $text-color;

        @media (max-width: 767px) {
          width: 100px;
          height: 46px;
          font-size: 14px;
        }

        &:hover {
          background-color: #1f1f1f;
        }
      }
    }
  }

  .sucess-popup {
    padding: 16px 24px;
    gap: 16px;
    background: #000000;
    border: 1px solid #373737;
    border-radius: 6px;
    width: 294px;
    height: 80px;
    position: fixed;
    top: 10px;
    right: 0px;
    z-index: 9999;
    transform: translateX(0%);
    transition: 0.4s;
    color: $text-color;

    &.showpopup {
      transform: translateX(100%);
    }

    img {
      width: 22px;
      height: 22px;
    }
  }
}

.topic-name {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.fa-angle-left,
.fa-angle-right {
  position: absolute;
  bottom: calc(100% - 35px);
  cursor: pointer;
}

.fa-angle-right {
  left: 0;

  @media (max-width: 767px) {
    top: calc(100% - 7px);
    left: 90px;
  }
}

.fa-angle-left {
  left: -50px;

  @media (max-width: 767px) {
    left: 33px;
    top: calc(100% - 7px);
  }
}

.sixmeetings-adss-inner-response-slider {
  position: relative;

  .carousel-slider {
    overflow: visible !important;
  }

  .control-dots {
    display: none;
  }

  .carousel-status {
    left: -60px !important;
    top: 25px !important;
    display: none;
  }

  .control {
    &-arrow {
      top: 0px !important;
      height: 40px;
      background-color: transparent !important;
      // font-family: FontAwesome;
      font-size: 18px !important;
    }

    &-prev {
      top: 18px !important;
      left: -80px !important;

      &::before {
        content: "\f104" !important;
        border: 0px !important;
      }

      &:not(.control-disabled) {
        &~.carousel-status {
          display: block;
          //update
          width: fit-content;
          //update
        }
      }
    }

    &-next {
      left: -30px !important;
      right: auto !important;
      top: 18px !important;

      &::before {
        content: "\f105" !important;
        border: 0 !important;
      }

      &:not(.control-disabled) {
        &~.carousel-status {
          display: block;
          width: fit-content;
        }
      }
    }
  }
}

.sixmeetings-adss-inner-response-row {
  text-align: left;
}

.sixmeetings-adss-inner-response-row+.sixmeetings-adss-inner-response-row {
  margin-top: 20px;
}

.sixmeetings-adss-modals-topics {
  padding: 10px 0px;
}

.sixmeetings-adss-modals-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #c0c0c0;
  font-size: 16px;
  margin-top: 5px;

  &-box {
    position: relative;
    height: 38px;
    width: 160px;
  }

  &-select {
    color: #000000;

    .models {
      &__menu {
        margin: 0.125rem auto;
        background: #1f1f1f;
        border: 1px solid #373737 !important;
      }

      &__option {
        background: #1f1f1f;
        color: white;

        &--is-hover {
          background-color: #1f1f1f;
        }

        &--is-selected {
          background-color: #131313;
        }

        &--is-focused {
          background-color: #131313;
        }

        &--is-active {
          background-color: #131313;
        }
      }

      &__group {
        padding: 0;
      }

      &__menu-portal {
        border: 1px solid #131313;
      }
    }

    .css-1dimb5e-singleValue {
      color: #c0c0c0;
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-t3ipsp-control {
      background-color: #131313 !important;
      border: 1px solid #e3c050 !important;
      box-shadow: none !important;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .css-tr4s17-option {
      background-color: #131313;
    }

    .css-d7l1ni-option {
      background-color: #131313;
    }

    .css-10wo9uf-option:active {
      background-color: #131313;
      background-color: #fff;
    }

    .css-13cymwt-control {
      background: #1f1f1f;
      border: 1px solid #373737;
    }

    .css-13cymwt-control:hover {
      border-color: #373737 !important;
    }
  }
}

.response-data {
  position: absolute;
  right: 100%;
  padding: 0 10px;

  @media (max-width: 576px) {
    top: calc(100% - 10px);

    left: 11%;
  }
}

.sixmeetings-overlay {
  @media (max-width: 767px) {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
  }

  // border: 1px solid red;
}

.btn {
  --bs-btn-font-weight: 700;
  --bs-btn-padding-x: 20px;
  --bs-btn-padding-y: 13px;
  --bs-btn-font-size: 14px;
  --bs-btn-line-height: 14px;
  --bs-btn-box-shadow: none;
  text-transform: uppercase;

  @media (min-width: 768px) {
    --bs-btn-padding-x: 19px;
    --bs-btn-padding-y: 9px;
    --bs-btn-font-size: 10px;
    --bs-btn-line-height: 13px;
  }

  @media (min-width: 992px) {
    --bs-btn-padding-x: 30px;
    --bs-btn-padding-y: 15px;
    --bs-btn-font-size: 16px;
    --bs-btn-line-height: 20px;
  }

  .btn-icn {
    margin-left: 8px;
  }
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #E3C050;
  --bs-btn-hover-color: var(--bs-btn-color);
  --bs-btn-hover-border-color: var(--bs-btn-border-color);
  --bs-btn-hover-bg: #1f1f1f;
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-border-color);
  --bs-btn-active-color: var(--bs-btn-hover-color);
}

.dropdown-menu {
  --bs-dropdown-bg: #131313;
  --bs-dropdown-color: #fff;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0;
  --bs-dropdown-font-size: 16px;
  --bs-dropdown-border-color: #1f1f1f;
  --bs-dropdown-border-radius: 4px;
  --bs-dropdown-item-padding-y: 11px;
  --bs-dropdown-item-padding-x: 16px;
  --bs-dropdown-link-color: #c0c0c0;

  >.dropdown-item {

    &:first-child {
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }

    &:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
}

.profile-dropdown {

  .dropdown-menu {

    >button {
      border: 0;
    }
  }
}

.offset-wrapper-i {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 558px;

  @media (min-width: 992px) {
    max-width: 800px;
  }
}

.offset-wrapper-ii {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
}

.intro-block {
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  padding-top: 60px;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: (26/18);
  }

  @media (min-width: 992px) {
    padding-top: 100px;
    font-size: 18px;
    line-height: (26/18);
  }

  h1,
  .h1 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;

    @media (min-width: 992px) {
      font-size: 48px;
      line-height: (56/48);
    }
  }

  .btn {
    margin-top: 12px;

    @media (min-width: 992px) {
      margin-top: 20px;
    }
  }
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase;
  justify-content: center;
  gap: 7px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 13px;

  @media (min-width: 992px) {
    gap: 12px;
    font-size: 18px;
    line-height: 20px;
  }

  >li {
    border-radius: 4px;
    background-color: #2B2B2B;
    color: #EBD18A;
    position: relative;
    font-weight: 500;
    padding: 5px 7px;

    @media (min-width: 992px) {
      padding: 8px 12px;
    }

    >.dropdown-menu {
      position: static;
      margin-top: 16px;
    }
  }

  .dropdown-toggle {
    margin-top: 0;

    &:after {
      border: solid currentColor;
      border-width: 2px 2px 0 0;
      transform: rotate(135deg);
      margin-left: 8px;
      width: 0.45em;
      height: 0.45em;
    }
  }

  .btn {

    &,
    &:focus {
      font: inherit;
      padding: 0;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      color: inherit;
    }
  }

  .icn {
    margin-right: 8px;
  }
}

.heading-head {
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 38px;
  }

  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 50px;
  }

  .sub-heading {
    display: block;
    color: #E3C050;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    margin: 0 0 5px;
    text-transform: uppercase;
  }

  h2,
  .h2 {
    margin-bottom: 0;
    font-size: 26px;
    line-height: 33px;

    @media (min-width: 768px) {
      font-size: 31px;
      line-height: 36px;
    }

    @media (min-width: 992px) {
      font-size: 48px;
      line-height: 56px;
    }

    +p {
      margin-bottom: 0;
      margin-top: 17px;

      @media (min-width: 768px) {
        margin-top: 20px;
      }
    }
  }
}

.has-border,
.imagetext-block:last-child {
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: url("../assets/images/line.svg");
    background-position: 50% 0;
    background-size: contain;
    background-repeat: no-repeat;
    height: 1px;
  }
}

.companies,
.goal,
.slider,
.testimonials-block,
.faq-block,
.easytool-block,
.imagetext-block,
.online-block,
.benefits-block {
  padding-top: 48px;
  padding-bottom: 48px;

  @media (min-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.goal {

  @media (min-width: 768px) {
    margin-bottom: -60px;
  }

  @media (min-width: 992px) {
    margin-bottom: -100px;
  }

  &:after {

    @media (min-width: 768px) {
      opacity: 0;
    }
  }
}

.companies {
  .logos {
    overflow: hidden;
    margin: 0 0 -20px;

    img {
      max-width: 100%;
      height: auto;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        margin: 0 20px 20px;
      }
    }
  }
}



.slider {
  img {
    width: 100%;
    height: auto;
  }
}

.faq-block {
  text-align: center;
}

.faq-list {
  font-size: 18px;
  line-height: 28px;

  >li {

    +li {
      margin-top: 40px;
    }
  }

  h3,
  .h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    font-family: inherit;
  }
}

.intro-columns-wrapper {
  margin-top: 64px;
  padding: 24px 16px;
  background-color: #0E0E0E;

  @media (min-width: 768px) {
    margin-top: 25px;
    padding: 0;
    background-color: transparent;
  }

  @media (min-width: 992px) {
    margin-top: 40px;
  }
}

.form-holder {
  max-width: 800px;
  margin: 50px auto;
  background: #1f1f1f;
  // padding: 30px;
  border: 2px solid transparent;
  overflow: hidden;
  transition: all 0.2s linear;

  &:hover {
    border-color: #E3C050;
    transition: all 0.2s linear;
  }

  input:not(.MuiInputBase-input),
  textarea,
  select {
    display: block;
    width: 100%;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  textarea {
    height: auto;
  }

  .submitted-message p {
    margin-bottom: 0;
  }

  .hs-form-private {
    width: 50%;
    display: block;
    text-align: center;
    margin: 0 auto;
    color: #fff;
  }

  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
    width: 100%;
    float: left;
  }

  .hs_submit.hs-submit {
    float: left;
    width: 100%;
  }

  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type="email"]::placeholder {
    color: #fff;
  }

  .hs_submit.hs-submit:focus {
    outline: none !important;
  }

  .hs_submit.hs-submit input:focus[type="submit"] {
    box-shadow: 0 0 0 0rem rgba(38, 143, 255, 0.5) !important;
  }

  ul.no-list.hs-error-msgs.inputs-list li {
    display: none;
  }

  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field label {
    display: none;
  }

  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type="email"] {
    background: transparent;
    border: 1px solid #565656;
    color: #fff !important;
  }

  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field::placeholder {
    color: #fff !important;
  }


  ul.no-list.hs-error-msgs.inputs-list li {
    display: none;
  }



  .submitted-message {
    overflow: auto;
    color: #fff !important;
    font-family: "Roboto", sans-serif !important;
    padding-bottom: 20px !important;
    font-size: 18px;
    position: relative;
  }

  .submitted-message:after {
    content: "";
    position: absolute;
    height: 1px;
    background: #dbb94e;
    width: 100%;
    /* bottom: -15px; */
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 250px;
    margin: 0 auto;
  }

  /* Footer contact Hubspot form */

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input::before,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .input::before {
    border: 0;
  }

  .host-forms.footer-cta-forms .hs-form-private {
    width: 100%;
  }

  .reqdemo-form-wrap .hs-form-private {
    width: 100%;
  }


  .host-forms.footer-cta-forms .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type="email"] {
    background: transparent;
    border: 1px solid #565656;
    color: #fff !important;
  }

  .host-forms.footer-cta-forms .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type="email"]::placeholder {
    background: transparent;
    color: #565656 !important;
  }

  .sixip-in-touch .host-forms.footer-cta-forms input#email-723bcf16-8080-48e3-9aec-820b2d4207c1,
  .sixip-in-touch .host-forms.footer-cta-forms input#email-93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .sixip-in-touch .host-forms.footer-cta-forms textarea#message-723bcf16-8080-48e3-9aec-820b2d4207c1 {
    background: transparent !important;
  }

  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field::placeholder {
    color: #fff !important;
  }

  .hs-form-private label {
    display: none;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 label,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-form-field label,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-form-field label:not(.hs-error-msg),

  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d label,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs-form-field label,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs-form-field label:not(.hs-error-msg) {
    display: none;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-input:not([type=file]),
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-input,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-input,

  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs-input:not([type=file]),
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs-input {
    background-color: transparent !important;
    border: 0px solid #515151 !important;
    border-bottom: 1px solid #515151 !important;
    color: #fff;
    padding-left: 0px;
    font-size: 16px;
    line-height: 19px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 35px;
    padding: 5px !important;
  }

  input:not(.MuiInputBase-input) {
    border: 0px solid #515151 !important;
    border-bottom: 1px solid #515151 !important;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-button,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-button input.hs-button.primary.large,

  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs-button,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs-button input.hs-button.primary.large {
    margin: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 12px;
    position: relative;
    text-align: center;
    background-color: #ff7a59;
    border-color: #ff7a59;
    color: #fff;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    padding: 12px 24px;
    color: #E3C050 !important;
    border: 2px solid #E3C050;
    padding: 10px 30px;
    text-transform: uppercase;
    background-color: #131313 !important;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    letter-spacing: 0.5px;
    border-radius: 5px;
    margin-top: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF !important;
    transition: all 0.2s ease-in-out;
    border: 1px solid #E3C050 !important;
    border-radius: 4px;
    padding: 15px 25px;
    display: block;
    width: 190px;
    margin: 0 auto;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .actions,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .actions {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-form-field label,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs-form-field label,
  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field label {
    transition: all 1s ease-in-out;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input input,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .input input {
    position: relative;
    padding-bottom: 1px;
    margin-bottom: 35px;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-input,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs-input,
  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
    position: relative;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-fieldtype-text.field.hs-form-field,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs-fieldtype-text.field.hs-form-field {
    width: 100%;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
    width: 65%;
    width: 100% !important;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
    float: none;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs_submit.hs-submit,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .hs_submit.hs-submit {
    float: none !important;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input:focus[type="text"],
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .form-control:focus,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .form-control:focus,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 input:focus[type="text"],
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d input:focus[type="text"] {
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #e3c050 !important;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field,
  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field .input,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .input,
  .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field {
    position: relative;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field::before,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input::before,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .input::before,
  .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field:before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #464749;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field::after,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input::after,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .input::after,
  .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field:after {
    content: "";
    width: 0;
    height: 1px;
    background-color: #c54747;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field:focus,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1:focus,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1.input:focus,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d:focus,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d.input:focus,
  .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input::placeholder,
  .contact-holder.events-capture-form .hs-form-private .input::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input::placeholder,
  form#hsForm_93a1e41b-20ba-4048-bd5f-b1d95ccf1c8d .input::placeholder {
    opacity: 1;
    font-size: 18px;
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input:-ms-input-placeholder,
  .contact-holder.events-capture-form .hs-form-private .input:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:-ms-input-placeholder {
    font-size: 18px;
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input:-ms-input-placeholder,
  .contact-holder.events-capture-form .hs-form-private .input:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input::-ms-input-placeholder {
    font-size: 18px;
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }

  .contact-holder.events-capture-form .hs-form-private .input.active::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active::placeholder {
    color: transparent;
  }

  .contact-holder.events-capture-form .hs-form-private .input.active:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active:-ms-input-placeholder {
    color: transparent;
  }

  .contact-holder.events-capture-form .hs-form-private .input.active::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active::-ms-input-placeholder {
    color: transparent;
  }

  .contact-holder.events-capture-form .hs-form-private .input.active:after,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active:after,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active:after {
    width: 100%;
  }

  .contact-holder.events-capture-form .hs-form-private .input:focus-within::before,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus-within::before,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus-within::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .contact-holder.events-capture-form .hs-form-private .input:focus-within::after,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus-within::after,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus-within::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #E3C050;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus::-webkit-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus::-webkit-input-placeholder {
    color: #8C8E95 !important;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus::-moz-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus::-moz-placeholder {
    color: #8C8E95 !important;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus:-ms-input-placeholder {
    color: #8C8E95 !important;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus:-moz-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:focus:-moz-placeholder {
    color: #8C8E95 !important;
  }

  .contact-holder.events-capture-form .hs-form-private .input input::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input input::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input textarea::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input input::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input textarea::-ms-input-placeholder {
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }

  .contact-holder.events-capture-form .hs-form-private .input.active::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active input::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active textarea::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active input::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active::placeholder {
    color: transparent;
  }

  .contact-holder.events-capture-form .hs-form-private .input.active input:-ms-input-placeholde form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active input:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active textarea:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active input:-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active textarea:-ms-input-placeholder {
    color: transparent;
  }

  .contact-holder.events-capture-form .hs-form-private .input.active input::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active input::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active textarea::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active input::-ms-input-placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input.active textarea::-ms-input-placeholder {
    color: transparent;
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input input::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input input::placeholder {
    color: #8C8E95 !important;
  }

  .contact-holder h2 {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 900 !important;
    font-size: 36px;
    line-height: 50px;
    text-align: left;
    color: #FFFFFF !important;
    margin-bottom: 40px;
  }

  .hs-form-private {
    width: 100%;
  }



  /* Single Company Events Email Capture */

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input:-webkit-autofill {
    background: #1f1f1f !important;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input:-webkit-autofill:focus {
    background: #1f1f1f !important;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type="email"] {
    background: transparent;
    border: 0px solid #565656;
    color: #fff !important;
    border-bottom: 1px solid #8C8E95;
    border-radius: 0;
    outline: 0 !important;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px rgba(102, 175, 233, 0.6) !important;
    font-size: 14px;
    line-height: 21px;
    padding: 0 5px;
  }

  .contact-holder.events-capture-form .hs-form-private {
    width: 100%;
  }


  .contact-holder.events-capture-form .hs-form-private ul.no-list.hs-error-msgs.inputs-list {
    margin: 0;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input::placeholder {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #8C8E95 !important;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input::placeholder,
  .contact-holder.events-capture-form .hs-form-private .input input::placeholder,
  .contact-holder.events-capture-form .hs-form-private .input input.hs-input::placeholder,
  .contact-holder.events-capture-form .hs-form-private .input .hs-input::placeholder,
  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type="email"] ::-webkit-input-placeholder {
    /* Edge */
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #8C8E95 !important;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type="email"] :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #8C8E95;
  }

  .contact-holder.events-capture-form .hs-form-private .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type="email"] ::placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #8C8E95;
  }


  ul.no-list.hs-error-msgs.inputs-list {
    display: block;
    list-style: none;
    margin: 5px;
    padding: 0;
    /* left: 0; */
    text-align: left;
    color: #e3c050;
  }

  label.hs-error-msg {
    display: block;
  }

  ul.no-list.hs-error-msgs.inputs-list li {
    display: block;
  }

  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field label {
    display: inline;
  }

  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field label span {
    display: none !important;
  }

  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type=email] {
    box-shadow: none;
  }

  legend.hs-field-desc {
    font-size: 12px;
    text-align: left;
  }

  #summit-order span {
    color: #ececec !important;
    background-color: #1f1f1f !important;
  }

  .events-company-logo img {
    max-height: 150px;
  }

  #summit-order ul li label.hs-error-msg,
  #summit-order ul.no-list.hs-error-msgs.inputs-list li label.hs-main-font-element {
    color: #e3c050 !important;
  }

  #summit-order .hs-form-private input:focus,
  #summit-order .hs-form-private textarea:focus {
    outline: 0;
    box-shadow: 0 0 0 0.5px #e3c150 !important;
  }


  #summit-order span {
    color: #ececec !important;
    background-color: #1f1f1f !important;
  }

  @media only screen and (max-width: 1200px) {

    .hs-form-private {
      width: 80%;
      display: block;
      text-align: center;
      margin: 0 auto;
      color: #fff;
    }

  }


  @media only screen and (max-width: 992px) {

    .hs_submit.hs-submit {
      float: none;
      margin-top: 20px;
    }


  }



  @media only screen and (max-width: 768px) {

    .hs_submit.hs-submit {
      float: none;
      margin-top: 20px;
    }

    .contact-holder h2 {
      font-size: 26px;
    }

    .hs-form-private {
      width: 100%;
      display: block;
      text-align: center;
      margin: 0 auto;
      color: #fff;
    }


  }

  @media only screen and (max-width: 560px) {

    .hs-form-private {
      width: 90%;
      display: block;
      text-align: center;
      margin: 0 auto;
      color: #fff;
    }

    .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
      width: 100%;
    }

    .hs_submit.hs-submit {
      float: none;
    }


    .host-forms.footer-cta-forms input.hs-button.primary.large,
    section.discover-hero.companies-main-hero input.hs-button.primary.large {
      margin-left: 0px !important;
      float: left !important;
    }

    .discover-hero h5 {
      margin: 30px 0 10px 0;
    }

    form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-button,
    form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .hs-button {
      margin: 15px 0;
    }

  }

  #summit-order .hs-form-private {
    background: #1F1F1F !important;
  }

  #summit-order .reqdemo-form-wrap {
    padding: 30px;
    background: #1F1F1F !important;
    float: left;
  }

  #summit-order .hs-form-private label {
    display: block !important;
    text-align: left !important;
    font-family: Roboto !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #FFFFFF !important;
    margin: 10px 0;
  }

  //  hs-form-private hsForm_e4fdfa1e-061b-4442-945d-fa8962cfa2f9 hs-form-e4fdfa1e-061b-4442-945d-fa8962cfa2f9 hs-form-e4fdfa1e-061b-4442-945d-fa8962cfa2f9_16b589b5-ff23-40ec-9055-3cc37b162f8f hs-form stacked

  #summit-order .hs-form-private input,
  #summit-order .hs-form-private select {
    border-radius: 0 !important;
    background: #131313 !important;
    border: 1px solid #3F3F3F !important;
    border-radius: 4px !important;
    float: left;
    display: block;
    width: 100%;
    margin: 0 0 20px 0;
    color: #fff !important;
  }

  #summit-order .hs-richtext.hs-main-font-element h1 {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: #FFFFFF;
    text-align: left;
    margin: 30px 0;
  }

  #summit-order textarea.form-control,
  #summit-order textarea {
    border-radius: 0 !important;
    background: #131313 !important;
    border: 1px solid #3F3F3F !important;
    border-radius: 4px !important;
    color: #fff !important;
  }


  #summit-order .hs_speaker_a_headshot.hs-speaker_a_headshot.hs-fieldtype-file.field.hs-form-field,
  #summit-order .hs_speaker_b_headshot.hs-speaker_b_headshot.hs-fieldtype-file.field.hs-form-field,
  #summit-order .hs_speaker_c_headshot.hs-speaker_c_headshot.hs-fieldtype-file.field.hs-form-field,
  #summit-order .hs_speaker_d_headshot.hs-speaker_d_headshot.hs-fieldtype-file.field.hs-form-field,
  #summit-order .hs_speaker_e_headshot.hs-speaker_e_headshot.hs-fieldtype-file.field.hs-form-field,
  #summit-order .hs_speaker_f_headshot.hs-speaker_f_headshot.hs-fieldtype-file.field.hs-form-field {
    margin: 20px 0 !important;
  }

  #summit-order li {
    list-style: none !important;
  }

  #summit-order ul.inputs-list input {
    width: auto !important;
    margin: 0 10px 0 0 !important;
    height: 20px !important;
    border: 1px solid #8C8E95 !important;
    border-radius: 4px !important;
    background-color: transparent !important;
  }

  #summit-order ul {
    padding: 0;
  }

  #summit-order span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #ECECEC;
  }

  #summit-order input.hs-button.primary.large {
    width: 106px !important;
    float: right !important;
    display: block;
  }

  #summit-order {
    //  margin: 30px 0 50px 0 !important;
  }


  #summit-order .hs-richtext.hs-main-font-element {
    text-align: left;
  }

  #summit-order .hs-richtext.hs-main-font-element {
    font-weight: 300 !important;
  }

  #summit-order .hs-richtext.hs-main-font-element a {
    color: #E3C050 !important;
  }

  .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field {
    position: inherit;
  }

  @media only screen and (max-width: 576px) {
    .reqdemo-form-wrap span {
      display: block;
    }

    .hs_submit.hs-submit {
      float: left;
    }
  }


  div#hbspt-form-1654116957858-426572374 input.hs-button.primary.large {
    outline: 0 !important;
    background-color: #000 !important;
    border-color: #e3c050 !important;
  }

  div#hbspt-form-1654116957858-426572374 input#email-435acda5-26af-4b1d-b79e-648430bc5ffe {
    background: transparent !important;
    color: #fff !important;
    border: 1px solid #565656 !important;
  }

  .hs-form-5c34190f-f0d7-4f48-9bc9-def89e8350a9_60a5d8dd-da3e-4a1b-9a56-ea37d6ef8ead .hs-button,
  .hs-form-5c34190f-f0d7-4f48-9bc9-def89e8350a9_60a5d8dd-da3e-4a1b-9a56-ea37d6ef8ead .hs-button:hover,
  .hs-form-5c34190f-f0d7-4f48-9bc9-def89e8350a9_60a5d8dd-da3e-4a1b-9a56-ea37d6ef8ead .hs-button:hover:not(.inactive),
  .hs-form-5c34190f-f0d7-4f48-9bc9-def89e8350a9_60a5d8dd-da3e-4a1b-9a56-ea37d6ef8ead .hs-button:focus,
  .hs-form-5c34190f-f0d7-4f48-9bc9-def89e8350a9_60a5d8dd-da3e-4a1b-9a56-ea37d6ef8ead .hs-button:active,
  .hs-form-5c34190f-f0d7-4f48-9bc9-def89e8350a9_60a5d8dd-da3e-4a1b-9a56-ea37d6ef8ead .hs-button:active:not(.inactive):not(.link) {
    background: #000000;
    border-color: #e3c050;
    color: #ffffff;
    font-size: 14px;
    line-height: 14px;
    font-family: Roboto;
    display: block;
  }


  input#email-5c34190f-f0d7-4f48-9bc9-def89e8350a9,
  .hs-input-6ix {
    background: transparent !important;
    border-color: #e3c050 !important;
    color: #fff !important;
  }

  .mag-social-bg .hs-form-private {
    width: 100% !important;
    margin-top: 30px;
  }

  #summit-order ul li label.hs-error-msg,
  #summit-order ul.no-list.hs-error-msgs.inputs-list li label.hs-main-font-element {
    color: #e3c050 !important;
  }

  #summit-order .hs-form-private input:focus,
  #summit-order .hs-form-private textarea:focus {
    outline: 0;
    box-shadow: 0 0 0 0.5px #E3C050 !important;
  }

  input#email-5c34190f-f0d7-4f48-9bc9-def89e8350a9,
  .hs-input-6ix {
    background: transparent !important;
    color: #fff !important;
    border: 1px solid #565656 !important;
  }

  #demo-hub-form input.hs-button.primary.large {
    margin: 30px auto 0 auto;
    border: 1px solid #E3C050 !important;
    border-radius: 4px !important;
    padding: 12px 25px;
    display: block;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }

  .actions {
    text-align: right;
  }

  #summit-order .hs-form-private input.hs-button.primary.large {
    width: 140px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    transition: all 0.2s ease-in-out;
    background: #000 !important;
    border: 1px solid #E3C050 !important;
    border-radius: 4px !important;
    padding: 12px 25px !important;
    display: inline-block;
    min-width: 140px !important;
    outline: none;
    margin-left: 15px !important;
  }

  #summit-order .hs-form-private input.hs-button.primary.large:hover {
    opacity: 0.8;
  }

  #summit-order .hs-form-private input:not(:disabled):not(.disabled):active[type="submit"] {
    outline: 0 !important;
    background-color: #000 !important;
    border-color: #e3c050 !important;
  }

  #summit-order .hs-form-private input.hs-button.primary.large.active,
  #summit-order .hs-form-private input.hs-button.primary.large.focus {
    outline: 0;
    background: #000;
  }

  #summit-order .hs-form-private input.hs-button input:focus[type="submit"] {
    box-shadow: 0 0 0 0rem rgba(38, 143, 255, 0.5);
  }

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input input:placeholder,
  input#email-220afe58-2e54-474a-befa-82977bf5e054:placeholder,
  input#email-220afe58-2e54-474a-befa-82977bf5e054::placeholder,
  input#email-220afe58-2e54-474a-befa-82977bf5e054::placeholder .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field input[type="email"]::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 input[type="email"]::placeholder,

  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input:placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 input[type="email"]::placeholder,
  form#hsForm_723bcf16-8080-48e3-9aec-820b2d4207c1 .input input:placeholder {
    color: #8C8E95 !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }
}

.intro-column-holder {
  background-color: #0E0E0E;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 51px 0;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    gap: 51px 0;
    gap: 30px 51px;
    padding: 40px 32px;
  }

  @media (min-width: 1200px) {
    gap: 30px 80px;
    padding: 62px 51px;
  }
}

.intro-column {
  background: #282828;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-weight: 300;
  padding: 19px 12px;
  font-size: 16px;
  line-height: 20px;
  width: 100%;

  @media (min-width: 768px) {
    width: calc(50% - 26px);
    padding: 19px 12px;
    font-size: 10px;
    line-height: 18px;
  }

  @media (min-width: 992px) {
    font-size: 16px;
    line-height: 28px;
    padding: 30px 20px;
  }

  @media (min-width: 1200px) {
    width: calc(50% - 40px);
  }

  h2,
  .h2 {
    color: #E3C050;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 21px;

    @media (min-width: 768px) {
      font-size: 13px;
      line-height: 17px;
    }

    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 27px;
    }

    +p {
      margin-top: 9px;

      @media (min-width: 992px) {
        margin-top: 15px;
      }
    }
  }

  .intro-img-wrap {
    margin-bottom: 8px;
    font-size: 30px;
    line-height: 1;
    color: #E3C050;

    @media (min-width: 768px) {
      margin-bottom: 12px;
      font-size: 40px;
    }

    @media (min-width: 992px) {
      margin-bottom: 20px;
      font-size: 50px;
    }
  }

  p {
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 768px) {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      height: 53px;
    }

    @media (min-width: 992px) {
      height: 84px;
    }

    @media (min-width: 1200px) {
      padding-left: 70px;
      padding-right: 70px;
    }

    +.tagline-list {
      margin-top: 19px;

      @media (min-width: 992px) {
        margin-top: 31px;
      }
    }
  }
}

.tagline-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  @media (min-width: 992px) {
    gap: 10px;
  }

  li {
    min-width: calc(33.333% - 10px);
    flex-grow: 1;
    flex-basis: 0;
  }

  span {
    display: block;
    border: 1px solid #E3C050;
    border-radius: 4px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-size: 9px;
    line-height: 11px;
    padding: 9px 4px;

    @media (min-width: 768px) {
      padding: 9px 8px;
    }

    @media (min-width: 992px) {
      font-size: 11px;
      line-height: 13px;
      padding: 14px 8px;
    }

    @media (min-width: 1200px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.intro-bottom-wrapper {
  padding-top: 25px;

  @media (min-width: 768px) {
    display: flex;
    padding-top: 0;
    gap: 0 136px;
    background-image: linear-gradient(180deg, rgba(227, 192, 80, 1) 0%, rgba(31, 31, 31, 0) 100%);
    background-size: 2px 70%;
    background-position: 50% 100%;
    background-repeat: no-repeat;
  }

  @media (min-width: 992px) {
    gap: 0 210px;
  }
}

.intro-bottom-column {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;

  @media (min-width: 768px) {
    text-align: left;
    padding: 25px 0 34px;
    width: calc(50% - 68px);
    font-size: 12px;
    line-height: 17px;
  }

  @media (min-width: 992px) {
    padding: 40px 0 54px;
    width: calc(50% - 105px);
    font-size: 18px;
    line-height: 27px;
  }

  +.intro-bottom-column {
    margin-top: 25px;

    @media (min-width: 768px) {
      margin-top: 0;
      text-align: right;
    }
  }

  h3,
  .h3 {
    font-family: inherit;
    margin-bottom: 0;
    color: #fff;
    font-size: 19px;
    line-height: 25px;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 21px;
    }

    @media (min-width: 992px) {
      font-size: 28px;
      line-height: 32px;
    }

    +p {
      margin-top: 12px;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.featured-image {

  img {
    width: 100%;
  }
}

.easytool-block {
  padding-bottom: 0;
  position: relative;
  z-index: 1;

  .heading-head {
    margin-bottom: 30px;
  }
}

.imagetext-block {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    text-align: left;
    font-size: 13px;
    line-height: 18px;
  }

  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 28px;
  }

  padding-top: 30px;
  padding-bottom: 30px;

  @media (min-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &.imagetext-block.alt {

    .imagetext-wrapper {
      flex-direction: row-reverse;
    }
  }

  &.imagetext-block:last-child {
    padding-bottom: 48px;

    @media (min-width: 768px) {
      padding-bottom: 60px;
    }

    @media (min-width: 992px) {
      padding-bottom: 100px;
    }
  }

  h2,
  .h2 {
    margin-bottom: 0;
    font-size: 26px;
    line-height: 33px;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (min-width: 992px) {
      font-size: 32px;
      line-height: 40px;
    }

    +p {
      margin-top: 12px;

      @media (min-width: 992px) {
        margin-top: 20px;
      }
    }
  }

  .featured-image {
    margin-top: 40px;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.imagetext-wrapper {

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  @media (min-width: 992px) {
    gap: 40px;
  }

  .featured-image {

    @media (min-width: 768px) {
      flex-shrink: 0;
      width: 58%;
      order: 1;
    }

    img {
      width: 100%;
    }
  }

  .imagetext-description {

    @media (min-width: 768px) {
      order: 2;
    }
  }
}

.three-col {
  color: #E9E9E9;
  padding-left: 19px;
  padding-right: 19px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;

  @media (min-width: 768px) {
    padding-left: 19px;
    padding-right: 19px;
    text-align: left;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    flex-wrap: wrap;
    background-color: #0E0E0E;
    border-radius: 8px;
    gap: 40px;
  }

  @media (min-width: 992px) {
    gap: 60px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    line-height: 24px;
  }

  .col {
    position: relative;

    @media (min-width: 768px) {
      padding-top: 28px;
      padding-bottom: 28px;
    }

    @media (min-width: 992px) {
      padding-top: 43px;
      padding-bottom: 43px;
    }

    +.col {
      margin-top: 37px;

      @media (min-width: 768px) {
        margin-top: 0;
      }
    }

    &:before {

      @media (min-width: 768px) {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(180deg, rgba(43, 43, 43, 0) 4.25%, #2B2B2B 51.04%, rgba(43, 43, 43, 0) 100%);
        background-repeat: no-repeat;
        width: 1px;
        left: -19px;
      }

      @media (min-width: 992px) {
        left: -32px;
      }
    }
  }

  .three-col-icn {
    display: block;
    margin-bottom: 15px;
  }

  h3,
  .h3 {
    color: inherit;
    margin-bottom: 0;
    font-family: inherit;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 20px;
    }

    @media (min-width: 992px) {
      font-size: 26px;
      line-height: 30px;
    }

    +p {
      margin-top: 12px;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.single-quoter-wrapper {

  +.single-quoter-wrapper {
    margin-top: 12px;

    @media (min-width: 768px) {
      margin-top: 25px;
    }

    @media (min-width: 992px) {
      margin-top: 40px;
    }
  }
}

.quoter-box {
  background-color: #131313;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  display: flex;
  font-size: 6px;
  line-height: 9px;
  gap: 0 11px;
  padding: 12px;

  @media (min-width: 768px) {
    font-size: 13px;
    line-height: 18px;
    gap: 0 23px;
    padding: 26px;
  }

  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 28px;
    gap: 0 36px;
    padding: 40px;
  }

  &:hover {
    color: #fff;
  }

  .quoter-image {
    flex-shrink: 0;
    width: 75px;

    @media (min-width: 768px) {
      width: 145px;
    }

    @media (min-width: 992px) {
      width: 224px;
    }

    img {
      width: 100%;
    }
  }

  .single-quoter-description {
    position: relative;
    padding-left: 22px;

    @media (min-width: 768px) {
      padding-left: 37px;
    }

    @media (min-width: 992px) {
      padding-left: 60px;
    }
  }

  .single-quoter-icn {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 12px;

    @media (min-width: 768px) {
      max-width: 25px;
    }

    @media (min-width: 992px) {
      max-width: 40px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  h4,
  .h4 {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 13px;

    @media (min-width: 768px) {
      font-size: 21px;
      line-height: 26px;
    }

    @media (min-width: 992px) {
      font-size: 32px;
      line-height: 40px;
    }

    +p {

      @media (min-width: 768px) {
        margin-top: 6px;
      }
    }
  }

  .single-quoter-cite-wrap {
    margin-top: 8px;

    @media (min-width: 768px) {
      margin-top: 15px;
      gap: 25px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    @media (min-width: 992px) {
      gap: 40px;
      margin-top: 24px;
    }
  }

  .quote-gold {
    color: #D3B054;
    font-weight: 700;
    font-size: 7px;
    line-height: 15px;

    @media (min-width: 768px) {
      font-size: 13px;
      line-height: 16px;
    }

    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .quoter-bottom-image {
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 188px;
    }

    @media (min-width: 992px) {
      max-width: 291px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  p {
    font: inherit;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.online-block {
  position: relative;
  font-weight: 700;
  z-index: 1;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  .container {
    position: relative;
  }

  p {
    font-weight: 400;
  }

  @media (min-width: 768px) {
    font-size: 10px;
    line-height: 12px;
    text-align: left;
    margin-bottom: -60px;
  }

  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: -100px;
  }

  &:before {
    content: '';
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(49, 49, 49, 0.2) 30%, rgba(46, 46, 46, 0.2) 70%, rgba(0, 0, 0, 0) 100%);
    background-repeat: no-repeat;
    background-position: calc(50% - 100px) 50%;
    background-size: 100% 70%;
    left: 40%;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
  }

  &:after {

    @media (max-width: 767px) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: url("../assets/images/line.svg");
      background-position: 50% 0;
      background-size: contain;
      background-repeat: no-repeat;
      height: 1px;
    }
  }

  .online-block-image {
    margin-top: 16px;

    @media (min-width: 768px) {
      flex-shrink: 0;
      width: calc(50% + 36px);
      margin-left: -36px;
      order: 1;
      margin-top: 0;
    }

    @media (min-width: 992px) {
      width: calc(50% + 75px);
      margin-left: -75px;
    }

    img {
      width: 100%;
    }
  }

  h2,
  .h2 {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 19px;
      line-height: 24px;
      margin-bottom: 7px;
    }

    @media (min-width: 992px) {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 15px;
    }
  }

  .online-block-wrapper {
    position: relative;

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .btn {
    margin-top: 24px;

    @media (min-width: 768px) {
      margin-top: 14px;
    }

    @media (min-width: 992px) {
      margin-top: 30px;
    }
  }

  .online-block-description {
    // position: relative;

    @media (min-width: 768px) {
      max-width: 36%;
      order: 2;
    }
  }

  .online-ico-announce {
    position: absolute;
    right: 0;
    top: -20px;
    // bottom: 9px;
    max-width: 68px;

    @media (min-width: 992px) {
      right: -70px;
      top: -20px;
      // bottom: -10px;
      max-width: 148px;
    }

    @media (min-width: 1200px) {
      right: -30px;
      // bottom: -10px;
      max-width: 168px;
      top: -20px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .block-text-dull {
    font-weight: 400;
    color: #8C8E95;
    font-size: 11px;
    line-height: 15px;
    margin-top: 10px;

    @media (min-width: 768px) {
      font-size: 8px;
      line-height: 10px;
      margin-top: 15px;
    }

    @media (min-width: 992px) {
      font-size: 16px;
      line-height: 20px;
      margin-top: 30px;
    }
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.benefits-block {
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: -60px;
  }

  @media (min-width: 992px) {
    margin-bottom: -100px;
  }

  &:after {

    @media (min-width: 768px) {
      opacity: 0;
    }
  }

  .featured-image {
    margin-top: 16px;

    @media (min-width: 768px) {
      margin: 0 auto;
      max-width: 540px;
    }

    @media (min-width: 1200px) {
      max-width: 790px;
    }

    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  .extra-wrapper {
    position: relative;
  }

  .captions-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .caption-col {
    text-align: left;
    width: calc(50% - 8px);
    font-size: 13px;
    line-height: 18px;

    @media (min-width: 768px) {
      font-size: 10px;
      line-height: 16px;
      position: absolute;
      z-index: 1;
      top: 50%;
      width: 160px;
    }

    @media (min-width: 992px) {
      width: 265px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .caption-col-left {
    text-align: left;

    @media (min-width: 768px) {
      left: 0;
      margin-top: -54px;
    }

    @media (min-width: 992px) {
      margin-top: -80px;
    }

    @media (min-width: 1200px) {
      left: 10px;
    }

    @media (min-width: 1400px) {
      left: 90px;
    }
  }

  .caption-col-right {

    @media (min-width: 768px) {
      text-align: right;
      right: 0;
      margin-top: -80px;
    }

    @media (min-width: 992px) {
      margin-top: -110px;
    }

    @media (min-width: 1200px) {
      right: 10px;
    }

    @media (min-width: 1400px) {
      right: 90px;
    }
  }

  h3,
  .h3 {
    font-family: inherit;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;

    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 18px;
    }

    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 28px;
    }

    +p {
      margin-top: 12px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.intro-block-bg {
  position: absolute;
  left: 0;
  top: 60px;
  height: 60%;
  right: 0;
  z-index: -1;

  @media (max-width: 768px) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-position: 50% 0;
    object-fit: cover;
  }
}

.intro-columns-pattern-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 28px;
  width: 62px;
  margin-top: 7px;
  z-index: -1;

  @media (min-width: 576px) {
    width: 53px;
    margin-top: 6px;
  }

  @media (min-width: 768px) {
    width: 53px;
    margin-top: 0;
    transform: translate(-50%, -50%);
  }

  @media (min-width: 1200px) {
    width: 81px;
    gap: 48px;
  }

  .icp-pattern {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.easytool-bg-pattern {
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: -350px;
  z-index: -1;

  @media (max-width: 767px) {
    display: none;
  }

  img {
    width: 100%;
  }
}

.mobile-hidden {

  @media (max-width: 767px) {
    display: none;
  }
}

.desktop-hidden {

  @media (min-width: 768px) {
    display: none;
  }
}

.d-none {
  display: none;
}

.sixPromoLoader {
  position: fixed;
  top: 0px;
  bottom: 0px;
  align-items: center;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;
  z-index: 999999999;
}

.sixPromoPopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(19, 19, 19, 0.7);
  backdrop-filter: blur(10px);
  overflow: auto;

  &-close {
    position: absolute;
    cursor: pointer;
    right: 0px;
    top: 0;
    padding: 10px 15px;
  }

  &-inner {
    position: relative;
    color: #fff;
    gap: 10px;
    width: 1000px;
    background: #2b2b2b;
    border: 1px solid #454648;
    border-radius: 6px;
    padding: 24px 24px;
    max-width: 95%;
    margin: 40px auto;


  }

  &-sm & {
    &-inner {
      width: 500px;
    }
  }

  &-header {
    &-heading {
      color: #E9E9E9;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }

    &-desc {
      color: #8C8E95;
      font-size: 14px;
      margin-top: 10px;
      text-align: center;
    }
  }

  &-content {
    width: 100%;
    padding: 20px 0px;
  }

  &-footer {
    &-btn {
      gap: 16px;

      &.btn-grey {
        width: 194px;
        height: 48px;
        color: #fff;
        border: 1px solid #454648;
        border-radius: 6px;

        &:active {
          background-color: #373737;
        }
      }

      &.btn-gold {
        width: 194px;
        height: 48px;
        color: #fff;
        border: 1px solid #e3c050;
        border-radius: 6px;
        background-color: #121212;
      }
    }

  }
}

.hs-form-field{
  overflow: hidden;

  ul{
    margin: 0;

    input[type="checkbox"]{
      min-height: 1px;
    }
  }
}

.MuiPopper-root{

  > div{
    background-color: #1f1f1f !important;
    border-color: #1f1f1f !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px, rgba(0, 0, 0, 0.14) 0 8px 10px 1px, rgba(0, 0, 0, 0.12) 0 3px 14px 2px;
    border-radius: 4px;
    color: #e9e9e9;
  }

  li{

    &:hover{
      background-color: rgba(255, 255, 255, 0.08);
    }

    &.Mui-selected{
      background-color: rgba(227, 192, 80, 0.16);
    }
  }
}

#menu-{

  .MuiPaper-elevation{
    background-color: #1f1f1f !important;
    border-color: #1f1f1f !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px, rgba(0, 0, 0, 0.14) 0 8px 10px 1px, rgba(0, 0, 0, 0.12) 0 3px 14px 2px;
    border-radius: 4px;
    color: #e9e9e9;

    li{

      &.MuiMenuItem-root:hover{
        background-color: rgba(255, 255, 255, 0.08);
      }
    }

    .Mui-selected{
      background-color: rgba(227, 192, 80, 0.16);
    }
  }
}

#header-language-menu{

  .MuiPaper-root.MuiPaper-elevation{
    background-color: #1f1f1f !important;
    border-color: #1f1f1f !important;
    box-shadow: 0px 4px 50px 0px rgba(0,0,0,0.4) !important;
    margin-top: 15px;
    border-radius: 11px;
    font-weight: 700;
    color: #e9e9e9;
    width: 500px;
    padding: 0px 8px;
  }

  .language-header {
    color: #575757;
    padding: 8px 8px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
  }

  .language-inner{
    display: flex;
    flex-wrap: wrap;

    li {
      color: #C0C0C0 !important;
      font-size: 14px !important;
      line-height: 1.5;
      width: 33.33%;
      border-radius: 8px !important;
      padding: 4px !important;

      &.language-item {
        &.active {
          color: rgb(227, 192, 80) !important;
        }
      }
    }

    .language-item-check {
      width: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      
      svg {
        width: 12px;
        margin-left: -2px;
      }
    }

  }

  .MuiMenuItem-root{
    font-size: 14px;
    line-height: 1.35;
    word-break: break-word;
    white-space: normal;
    width: calc(50% - 3px);

    @media (min-width: 576px){
      width: calc(33.333% - 4px);
    }

    &:hover{
      background-color: #2b2b2b;
      color: #fff;
    }
  }
}

body{
  top: 0 !important;
}

#simple-menu{

  .MuiPaper-elevation{
    background-color: #1f1f1f !important;
    border: 1px solid #2B2B2B !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.4) !important;
    border-radius: 11px;
    width: 145px;
  }

  .MuiList-padding{
    padding-top: 0;
    padding-bottom: 0;

    li{
      padding: 0;
    }
  }
}

.MuiPickersPopper-root{

  .MuiPaper-elevation{
    background-color: #1f1f1f !important;
    border: 1px solid #2b2b2b !important;
    box-shadow: 0 4px 50px 0 #131313 !important;
  }

  .MuiPickersDay-today{
    border-color: #2b2b2b !important;
    color: #fff !important;
  }

  .MuiPickersCalendarHeader-switchViewButton,
  .MuiPickersArrowSwitcher-button,
  .MuiPickersDay-root{
    color: #e9e9e9;
  }

  .MuiTypography-caption{
    color: #fff;
    font-weight: 500;
  }

  .MuiPickersYear-yearButton{
    border-radius: 8px !important;
  }

  .MuiPickersArrowSwitcher-button,
  .MuiPickersDay-root{
    border-radius: 4px !important;
  }

  .MuiPickersArrowSwitcher-button:hover,
  .Mui-selected,
  .MuiPickersYear-yearButton:hover,
  .MuiPickersDay-root:hover{
    background-color: #454648 !important;
    color: #fff !important;
  }

  .MuiDialogActions-root{

    > .MuiButtonBase-root{
      background-color: #2b2b2b;
      color: #fff;

      &:hover{
        background-color: #454648;
        color: #fff;
      }
    }
  }

  .MuiDivider-root{
    border-color: #373737;
  }

  .MuiMultiSectionDigitalClockSection-root:not(:first-of-type){
    border-left-color: #373737;
  }

  .MuiDateCalendar-root,
  .MuiMultiSectionDigitalClock-root{
    max-height: 362px;
  }

  .MuiPickersLayout-landscape{
    width: 661px;
  }

  .MuiPickersPopper-paper{
    box-shadow: none !important;
  }

  .MuiMenuItem-root{
    color: #8C8E95;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

    &:hover{
      background-color: #000;
      color: #fff;
      border-color: #E3C050;
    }
  }
}

.pac-container{
  background-color: #1f1f1f !important;
  border: 1px solid #2b2b2b !important;
  box-shadow: 0 4px 50px 0 #131313 !important;
  border-radius: 0 0 11px 11px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: -1px;

  &.pac-logo{

    &:after{
      background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white.png);
      background-size: 116px 21px;
      height: 21px;
    }
  }

  .pac-item{
    color: #8C8E95;
    font-size: 14px;
    line-height: 1.35;
    padding: 6px 16px;
    border: 0;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;

    &:hover{
      background-color: #454648;
      color: #fff;

      .pac-item-query{
        color: #fff;
      }
    }
  }

  .pac-item-query{
    font: inherit;
    color: #e9e9e9;
    transition: color 0.3s ease;
  }

  .pac-icon{
    margin-top: 0;
  }
}

.carousel-slider{
  padding-bottom: 60px;
  padding-top: 30px;
  margin-top: -30px;

  .carousel-status{
    margin-top: 0;
    margin-bottom: 0;
  }

  .control {

    &-dots {
      bottom: 0;

      .dot {
        background: silver !important;
        box-shadow: none !important;
        margin: 0 5px;

        &.selected {
          background: #e3c050 !important;
        }
      }
    }

    &-arrow {
      top: auto !important;
      bottom: 0 !important;
      background: transparent !important;
      border: 1px solid #373737 !important;
      border-radius: 4px !important;
      font-size: 16px !important;
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      padding: 0 !important;


      &:hover {
          background: #373737 !important;
      }

      &::before {
          display: none;
      }
    }
  }
}
  
.card-group p{
  margin: 5px 0 0;
  color: #BB4A4A;
  font-size: 13px;
  line-height: 17px;
}


.Toastify {
  &__toast {
    background: #1F1F1F;
    border: 1px solid #373737;
    border-radius: 11px;
    padding: 12px;
    box-shadow: none;
    font-size: 16px;
    line-height: 20px;
    color: white;

    &-body {
      position: relative;
      padding-left: 20px;

      &:before {
        width: 3px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: #2B2B2B;
        content: "";
        border-radius: 3px;
      }
    }

    &--success {
      .Toastify__toast-body {
        &:before {
          background: #11B585;
        }
      }
    }

    &--warning {
      .Toastify__toast-body {
        &:before {
          background: #e3c050;
        }
      }
    }

    &--error {
      .Toastify__toast-body {
        &:before {
          background: #BB4A4A;
        }
      }
    }

    &--info {
      .Toastify__toast-body {
        &:before {
          background: silver;
        }
      }
    }

    &-icon {
      display: none;
    }
  }
  
  &__close {
    &-button {
      color: #8C8E95;
      align-self: auto;
      font-size: 0;
      line-height: 0;

      > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e3c050 !important;
  height: calc(100% + 5px) !important;
  width: calc(100% + 1px) !important;
  top: -5px !important;
  left: -1px !important;
  border-width: 1px !important;
  border-radius: 4px !important;
}

.text-area .Mui-focused .MuiOutlinedInput-notchedOutline {
  left: 0 !important;
  width: calc(100% + 0px) !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  height: calc(100% + 5px) !important;
  top: -5px !important;
  border-width: 1px !important;
  border-radius: 4px !important;
}

.MuiFormControl-root > div:not(.MuiOutlinedInput-notchedOutline) {
  position: relative !important;
  height: 100%;
  width: 100%;
}


.date-time-pm {
  position: relative;

  .loader-holder {
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(19, 19, 19, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

a:-webkit-any-link {
  text-decoration: none;
}

.timezone {
  .timezone {
    &__option {
      padding: 5px 5px 5px 20px !important;
      font-size: 12px !important;
      line-height: 16px !important;
  
      &:after {
        position: absolute;
        left: 5px;
        top: 5px;
        content: "";
        width: 12px;
        height: 12px;
      }
  
      &--is-selected {

        &:after {
          background: url("../assets/images/icon-check.svg");
          background-size: cover;
        }
      }
    }
  }
}

.timezone {
  &__single-value {
    color: white !important;
    background: none !important;
  }

  &__control {
    &--is-focused {
      .timezone__input-container {
        background: none !important;
      }
    }
  }

  &__menu {
    margin: 0.25rem auto !important;
  }

  &__option {
    background-color: #131313 !important;
    color: #C0C0C0 !important;
    cursor: pointer !important;
    padding: 10px 10px 10px 40px !important;
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    height: auto !important;
    position: relative;
    // border-top: 1px solid #373737;

    &:after {
      position: absolute;
      left: 10px;
      top: 10px;
      content: "";
      width: 18px;
      height: 18px;
    }

    &--is-focused {
      color: white !important;
      background-color: #131313 !important;
    }

    &--is-selected {
      color: white !important;
      background-color: #131313 !important;

      &:after {
        background: url("../assets/images/icon-check.svg");
        background-size: cover;
      }
    }
  }

  

  &__group {
    padding: 0;
    max-height: 200px;
  }

  &__menu-portal {
    // border: 1px solid darkblue;
  }

}

.eventsColorPicker {
  &-btn {
      width: 25px;
      height: 25px;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      border: 1px solid #000;
      box-shadow: 0px 0px 2px;
      cursor: pointer;
  }

  &-menu {
      position: relative;
  }
}

.colorPickerMenu{
  
}

#header-language-menu-button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-language-box-top-name {
    display: flex;
    align-items: center;
  }
}
