.userLogin {
    &-social {
        iframe {
            width: 100% !important;
        }

        &-btn {
            padding: 10px 20px;
            text-align: center;
            border: 1px solid #373737;
            border-radius: 6px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            height: 48px;
            color: #fff;
            background: transparent;

            img {
                margin-right: 10px;
            }

            &:active,
            &:hover {
                background-color: #373737;
            }
        }
    }

    &-or {
        position: relative;
        text-align: center;
        color: #575757;
        font-size: 14px;
        margin: 25px 0px;

        &-text {

            &::before,
            &::after {
                content: "";
                height: 1px;
                background-color: #373737;
                position: absolute;
                top: 50%;
                width: 45%;
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }
        }
    }

    &-email {
        width: 100%;

        &-input {
            background-color: #131313 !important;
            color: #fff !important;
            border: 1px solid #373737 !important;
            border-radius: 8px !important;
            outline: none !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            height: 46px;
            resize: none;
            padding: 0.5rem 0.75rem;
            margin-bottom: 20px;
            width: 100%;
        }


        &-btn {
            padding: 10px 20px;
            text-align: center;
            border: 1px solid #e3c050;
            border-radius: 6px;
            background-color: #000;
            color: #fff;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            height: 48px;
            text-transform: uppercase;

        }

        &-msg {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            min-height: 48px;
        }

        &-link {
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            color: #8C8E95;
            text-decoration: underline;
            display: inline-block;
            vertical-align: top;

            &:hover {
                color: #e3c050;
                text-decoration: none;
            }
        }
    }
}