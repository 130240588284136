@mixin vendor-prefix($property, $value) {
    #{$property}: $value;

    @each $prefix in("webkit", "moz", "ms", "o") {
        #{'-' + $prefix + '-' + $property}: $value;
    }
}

@mixin vendor-placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

.meeting6ix-layout {
    &-footer {
        // background: #131313;
        z-index: 99;
        position: relative;
        padding: 48px 24px 48px;

        @media (max-width: 767px) {
            padding: 48px 16px;
        }

        &-top {
            gap: 10px;
            justify-content: space-between;

            &-logo {
                width: 42px;

                img {
                    max-width: 100%;
                }
            }

            &-social {
                align-items: center;

                &-text {
                    color: #C0C0C0;
                    font-size: 14px;
                    margin-right: 20px;

                    @media (max-width: 767px) {
                        font-size: 13px;
                        margin-right: 15px;
                    }
                }

                &-link {
                    cursor: pointer;
                    line-height: 24px;
                    padding: 0px 5px;
                    color: #C0C0C0;

                    &:last-child {
                        padding-right: 0px;
                    }

                    &:hover {
                        color: white;
                    }
                }
            }
        }

        &-divider {
            width: 100%;
            height: 1px;
            background-color: #373737;
            margin: 36px 0px;

            @media (max-width: 1499px) {
                margin: 24px 0px;
            }
        }

        &-access {
            width: 100%;

            &-heading {
                color: white;
                text-align: center;
                font-size: 26px;
                font-weight: 700;

                @media (max-width: 1499px) {
                    font-size: 22px;
                }

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }

            &-text {
                color: #8c8e95;
                text-align: center;
                font-size: 14px;

                @media (max-width: 1199px) {
                    font-size: 13px;
                }
            }

            &-row {
                margin-top: 36px;
                gap: 12px;

                @media (max-width: 767px) {
                    flex-direction: column;
                }

                &-box {
                    padding: 10px;
                    background-color: #1F1F1F;
                    font-size: 14px;
                    color: #C0C0C0;
                    @include vendor-prefix(border-radius, 8px);
                    flex: 1;
                    gap: 5px;
                    align-items: center;

                    @media (max-width: 1199px) {
                        font-size: 13px;
                    }

                    span {
                        background: #7a6d40;
                        @include vendor-prefix(border-radius, 4px);
                        padding: 3px 6px;
                    }
                }
            }
        }

        &-menu {
            gap: 48px;
            align-items: center;
            justify-content: center;

            @media (max-width: 1199px) {
                gap: 36px;
            }

            @media (max-width: 767px) {
                gap: 20px;
            }

            &-link {
                color: #e9e9e9;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: none;

                @media (max-width: 767px) {
                    font-size: 13px;
                }

                &:hover {
                    color: #e3c050;
                }
            }
        }

        &-cmsmenu {
            align-items: center;
            justify-content: end;
            margin-top: auto;

            &-inner {
                gap: 36px;
                align-items: center;
            }

            &-link {
                position: relative;

                &-btn {
                    color: #C0C0C0;
                    font-size: 14px;
                    position: relative;
                    align-items: center;
                    display: flex;
                    white-space: nowrap;
                    cursor: pointer;
                    text-decoration: none;

                    span {
                        text-transform: uppercase;
                        margin-left: 8px;
                    }

                    .header-language-box-top-name {
                        display: flex;
                        align-items: center;
                    }

                    &.active,
                    &:hover {
                        color: #e3c050;

                        svg path {
                            // &[fill] {
                            //     fill: #e3c050;
                            // }

                            &[stroke] {
                                stroke: #e3c050;
                            }
                        }
                    }
                }

                &-dropdown {
                    .MuiPaper-root {
                        @include vendor-prefix(border-radius, 11px);
                        border: 1px solid #373737;
                        background: #1F1F1F;
                        box-shadow: 0px 4px 50px 0px #131313;
                        min-width: 200px;
                        max-width: 90%;
                    }

                    &-language {
                        &-header {
                            color: #575757;
                            padding: 8px 8px;
                            font-size: 15px;
                        }

                        .MuiPaper-root {
                            width: 500px;
                            padding: 0px 8px;
                            max-height: 70%;
                        }

                        &-inner {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        &-item {
                            color: #C0C0C0 !important;
                            font-size: 14px !important;
                            width: 33.33%;
                            @include vendor-prefix(border-radius, 8px !important);
                            padding: 4px !important;

                            &-check {
                                width: 15px;

                                svg {
                                    width: 10px;
                                    height: auto;
                                }
                            }

                            &.active {
                                color: #e3c050 !important;
                            }

                            @media (max-width: 767px) {
                                font-size: 13px !important;
                            }
                        }
                    }
                }
            }

            @media (max-width: 1024px) {
                justify-content: center;
                margin-top: 20px;

                &-inner {
                    gap: 24px;
                }

                &-link {
                    &-btn {
                        font-size: 13px;
                    }
                }
            }
        }

        &-content {
            width: 100%;

            @media (max-width: 1199px) {
                flex-direction: column;
            }

            &-space {
                margin-bottom: 20px;

                @media (max-width: 1499px) {
                    margin-bottom: 16px;
                }
            }

            &-left {
                padding-right: 24px;
                flex: 1;
                gap: 24px;
                flex-direction: column;

                @media (max-width: 1199px) {
                    padding-right: 0px;
                }

                &-row {
                    gap: 24px;

                    @media (max-width: 1366px) {
                        gap: 16px;
                    }

                    @media (max-width: 767px) {
                        flex-direction: column;
                    }

                    &-content {
                        width: 44%;

                        @media (max-width: 767px) {
                            width: 100%;
                        }

                        &-heading {
                            color: #e3c050;
                            font-size: 16px;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin-bottom: 16px;

                            @media (max-width: 1499px) {
                                font-size: 14px;
                            }

                            @media (max-width: 767px) {
                                margin-bottom: 10px;
                            }
                        }

                        &-text {
                            color: #C0C0C0;
                            font-size: 14px;

                            @media (max-width: 1199px) {
                                font-size: 13px;
                            }

                            ul {
                                list-style-type: disc;
                                padding-left: 20px;

                                li {
                                    margin-bottom: 2px;
                                }
                            }
                        }
                    }

                    &-menu {
                        flex: 1;
                        gap: 20px;

                        @media (max-width: 1499px) {
                            gap: 16px;
                        }

                        @media (max-width: 767px) {
                            flex-direction: column;
                        }

                        &-box {
                            flex-direction: column;
                            flex: 1;

                            &-heading {
                                color: #e9e9e9;
                                font-size: 16px;
                                font-weight: 700;
                                text-transform: uppercase;
                                margin-bottom: 16px;

                                @media (max-width: 1499px) {
                                    font-size: 14px;
                                }

                                @media (max-width: 767px) {
                                    margin-bottom: 10px;
                                }
                            }

                            &-link {
                                color: #C0C0C0;
                                font-size: 14px;
                                margin-bottom: 10px;
                                text-decoration: none;

                                @media (max-width: 1199px) {
                                    font-size: 13px;
                                }

                                @media (max-width: 767px) {
                                    margin-bottom: 5px;
                                }

                                &:hover {
                                    color: #e3c050;
                                }
                            }
                        }
                    }
                }
            }

            &-right {
                width: 30%;
                gap: 48px;
                flex-direction: column;

                @media (max-width: 1499px) {
                    gap: 30px;
                }

                @media (max-width: 1199px) {
                    width: 100%;
                    margin-top: 24px;
                }

                &-box {
                    &-heading {
                        color: #e9e9e9;
                        font-size: 16px;
                        font-weight: 700;
                        text-transform: uppercase;
                        margin-bottom: 16px;

                        @media (max-width: 1499px) {
                            font-size: 14px;
                        }

                        @media (max-width: 767px) {
                            margin-bottom: 10px;
                        }
                    }

                    &-text {
                        color: #C0C0C0;
                        font-size: 14px;

                        @media (max-width: 1199px) {
                            font-size: 13px;
                        }

                        &-sm {
                            font-size: 9px;
                        }
                    }
                }
            }
        }

        &-bottom {
            padding-top: 36px;

            &-text {
                color: #e3c050;
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;

                @media (max-width: 1499px) {
                    font-size: 14px;
                }
            }
        }
    }
}